@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TT Commons";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/ttcommons-regular.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/ttcommons-black.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/ttcommons-bold.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/ttcommons-demibold.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/ttcommons-extrabold.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/ttcommons-extralight.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: normal;
  font-style: italic;
  src: url("../fonts/ttcommons-italic.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/ttcommons-light.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/ttcommons-medium.woff") format("woff");
}
@font-face {
  font-family: "TT Commons";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/ttcommons-thin.woff") format("woff");
}

@layer utilities {
  .h1 {
    @apply text-navy-900 font-bold text-20px sm:text-28px md:text-40px leading-normal md:leading-44px;
    font-feature-settings: "liga" off;
  }
  .h2 {
    @apply text-navy-900 font-bold text-22px leading-26px;
  }
  .h3 {
    @apply text-gray-600 font-medium text-16px leading-20px;
  }
  .subtitle1 {
    @apply text-navy-900 font-normal text-22px leading-26px;
  }
  .subtitle2 {
    @apply text-navy-900 font-normal text-20px leading-24px;
  }
  .tableItem {
    @apply text-navy-900 font-medium text-18px leading-20px;
  }
  .tableItemNumber {
    @apply tableItem;
    font-feature-settings: "tnum" on, "lnum" on;
  }
  .paragraph {
    @apply text-navy-900 text-18px leading-22px;
    font-feature-settings: "liga" off;
  }
  .explanatoryText {
    @apply text-navy-900 text-16px leading-20px;
  }
  .link {
    @apply inline underline hover:underline focus:underline transition-colors text-lunchboxBlue-800 hover:cursor-pointer hover:text-lunchboxBlue-900 focus:text-lunchboxBlue-900 rounded-default focus-visible:outline-chalkboard700;
  }
  .explanatoryTextLink {
    @apply inline underline hover:underline focus:underline explanatoryText text-lunchboxBlue-800 hover:text-lunchboxBlue-900 cursor-pointer focus:text-lunchboxBlue-900 rounded-default focus-visible:outline-chalkboard700;
  }
  .fieldLabel {
    @apply text-navy-900 font-semibold text-15px leading-20px uppercase;
  }
  .inputLabel {
    @apply text-navy-900 font-semibold text-18px leading-20px mb-0 tabular-nums lining-nums;
  }
  .buttonLabel {
    @apply text-navy-900 font-semibold text-18px leading-18px;
  }
  .formInput {
    @apply paragraph p-8px rounded-2px resize-none text-navy-900 bg-white focus:outline-none appearance-none;
    /* placeholder:text-gray-600  */
    /* shadow-input shadow-gray-200 enabled:hover:shadow-gray-600 enabled:focus:shadow-chalkboard-700 enabled:focus:shadow-inputFocus
    aria-invalid:shadow-redApple-700 hover:aria-invalid:shadow-redApple-700 focus:aria-invalid:shadow-redApple-700 focus:aria-invalid:shadow-inputFocus hover:aria-invalid:shadow-inputFocus; */
  }
  .formError {
    @apply flex text-redApple-700 explanatoryText;
  }
  .focusRing {
    @apply focus-visible:ring-chalkboard-700;
  }
  .textareaPseudo {
    @apply formInput shadow-default border border-navy-900 overflow-auto bg-white;
  }
  .paginationBar {
    @apply h-56px relative overflow-hidden w-full border-b border-cream-300 py-8px px-16px flex justify-between items-center;
  }
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
  body {
    --pdtFont: theme(fontFamily.TT);
    --baseTextColor: theme(colors.navy.900);
    --baseInputBorderColor: theme(colors.cream.300);
    --baseInputHoverBorderColor: theme(colors.cream.500);
  }
  .listItemStyle {
    position: relative;
    padding-inline-start: 12px;
    &:before {
      content: "";
      position: absolute;
      top: 9px;
      left: -4px;
      width: 4px;
      height: 4px;
      background: var(--gray-800);
      border-radius: 50%;
    }
  }
  .menuOpenStyle {
    &[data-headlessui-state="open"] {
      background-color: theme(colors.cream.300);
    }
  }
  .formInputBorderStyle {
    --borderColor: theme(colors.cream.300);
    border: none;
    outline: none;
    box-shadow: 0 0 0 1px var(--borderColor);
    &[aria-invalid="true"] {
      --borderColor: theme(colors.redApple.700);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--borderColor);
      &:not([aria-invalid="true"]) {
        --borderColor: theme(colors.chalkboard.700);
      }
    }
    &:hover:not(:focus):not([disabled]) {
      box-shadow: 0 0 0 1px var(--borderColor);
      --borderColor: theme(colors.cream.500);
    }
    &[disabled] {
      background-color: white;
      color: theme(colors.gray.400);
      cursor: not-allowed;
    }
    &[data-headlessui-state~="open"] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .formInputBorderSimpleStyle {
    --borderColor: theme(colors.cream.300);
    border: none;
    outline: none;
    box-shadow: 0 0 0 1px var(--borderColor);
    &:focus {
      box-shadow: 0 0 0 1px var(--borderColor);
      &:not([aria-invalid="true"]) {
        --borderColor: theme(colors.cream.500);
      }
    }
    &:hover:not(:focus):not([disabled]) {
      box-shadow: 0 0 0 1px var(--borderColor);
      --borderColor: theme(colors.cream.500);
    }
    &[data-headlessui-state~="open"] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .selectDropDownOptionStyle {
    &:hover {
      background: theme(colors.gray.100);
    }
    &[data-headlessui-state~="selected"],
    &:active {
      background: theme(colors.gray.200);
    }
    &:not(:hover)[data-headlessui-state~="active"] {
      box-shadow: inset 0 0 0 2px theme(colors.chalkboard.700);
    }
  }
  .hideScrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .hideScrollbars::-webkit-scrollbar {
    display: none;
  }
  .dashboardFilterToolbar {
    @media (max-width: 1460px) {
      .dateDisplay {
        width: fit-content;
      }
      .frequencySelect {
        width: 96px;
      }
      .pendingSessionsSelect {
        width: 145px;
        .hideLabel {
          display: none;
        }
      }
    }
    @media (max-width: 1190px) {
      .dateSelect {
        width: 140px;
      }
    }
    @media (max-width: 900px) {
      .dateDisplay {
        display: none;
      }
    }
  }
  .graphs {
    container: graphs / inline-size;
  }
  @container graphs (max-width: 750px) {
    .graph-container {
      flex-direction: column;
    }
    .chart-details {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }
    .chart-data {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
    .graph {
      width: 100%;
    }
    .graphs-hide {
      display: none;
    }
    .graphs-show {
      display: block;
    }
    .percent-change {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}
